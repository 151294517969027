<template>
  <en-dialog :model-value="modelValue" title="补货单列表" @close="$emit('update:model-value', false)" width="80%">
    <tabs-maintain
      v-model="tabs.active"
      :options="[
        { label: '未处理', value: 'N' },
        { label: '已处理', value: 'Y' }
      ]"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SPRPLNMT"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {},
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/replenishment/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { processedCode: this.tabs.active }
            }
          }
        },
        config: {
          WAREHOUSE_NAME: { header: { filter: { type: 'text', field: 'warehouseName' } } },
          PREPARED_BY: { header: { filter: { type: 'text', field: 'preparedByName' } } },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } } } },
          MODIFIED_BY: { header: { filter: { type: 'text', field: 'modifiedByName' } } },
          AUDIT_DATETIME: { header: { filter: { type: 'date', field: ['auditStartDate', 'auditEndDate'], props: { type: 'daterange' } } } }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
